import * as React from "react";
import VideoSrc from "../assets/Trailer.mp4";

export default function WatchTrailer() {

    return (
        <div className="watch-page-container">
            <div className="landing-page-video">
                <iframe 
                src={VideoSrc} 
                width="1080" 
                height="480" 
                frameBorder="0" 
                allowFullScreen 
                className="video"
                title="watch-page-video"
                />
            </div>
        </div>
    );
}


