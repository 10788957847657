import * as React from "react";
import { ReactComponent as YoutubeIcon } from "@fortawesome/fontawesome-free/svgs/brands/youtube.svg";
import { ReactComponent as FacebookFIcon } from "@fortawesome/fontawesome-free/svgs/brands/facebook-f.svg";
import { ReactComponent as TwitterIcon } from "@fortawesome/fontawesome-free/svgs/brands/twitter.svg";
import { ReactComponent as InstagramIcon } from "@fortawesome/fontawesome-free/svgs/brands/instagram.svg";
const Footer = (): JSX.Element => {
  const items = [
    { name: "Stedfast", email: "stedfastbaptistkjv@gmail.com", phone: "(682) 253-4066" },
    { name: "Stedfast OKC", email: "stedfastokc@gmail.com", phone: "(405) 466-5344" },
    { name: "Pure Words", email: "purewordsbaptist@gmail.com", phone: "(832) 767-4436" },
    { name: "Contact", email: "sodomitedeception@gmail.com" }
  ];

  const icons = [
    { Component: YoutubeIcon, href: "https://www.youtube.com/channel/UCPKESPZRgjwbNfYDq0XYT8Q" },
    { Component: FacebookFIcon, href: "https://www.facebook.com/SBCKJV" },
    { Component: TwitterIcon, href: "https://twitter.com/stedfastkjv" },
    { Component: InstagramIcon, href: "https://www.instagram.com/williamtyndale2013/" }
  ]

  return (
    <footer>
      <h2>Representation</h2>
      <div className="items">
        {items.map(({ name, email, phone }) => (
          <div className="item" key={name}>
            <h4>{name}</h4>
            <p>{email}</p>
            <p>{phone}</p>
          </div>
        ))}
      </div>
      {/* <p>A project by <a target="_blank" href="http://sbckjv.com/">Stedfast Baptist Church</a></p> */}
      <div className="bottom">
        <p>Site inspired by Leviticus 20:13</p>
        <ul className="icons">
          {icons.map(({ href, Component }) => (
            <li className="icon" key={href}><a href={href} target="_blank" rel="noreferrer"><Component /></a></li>
          ))}
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
