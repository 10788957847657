import * as React from "react";

export default function Watch() {

    const myComponentStyle = {

    }

    return (
        <div className="watch-page-container">
            <div className="landing-page-video">
                {/* <iframe
                    src="https://archive.org/embed/THE_SODOMITE_DECEPTION"
                    width="1080"
                    height="480"
                    frameBorder="0"
                    allowFullScreen
                    className="video"
                    title="watch-trailer-video"
                /> */}
                <iframe src="https://embed.godresource.com#documentaries?hourLimit=10000000&autoplay=1" //https://cdn-02.godresource.com.us-southeast-1.linodeobjects.com/sodomitedeception/sodomitedeception.mp4
                    allow='autoplay'
                    allowFullScreen
                ></iframe>
            </div>
        </div>
    );
}


